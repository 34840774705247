// extracted by mini-css-extract-plugin
export var box = "TwoBoxes-module--box--1e89f";
export var container = "TwoBoxes-module--container--9ef1b";
export var content = "TwoBoxes-module--content--4296b";
export var description = "TwoBoxes-module--description--76d17";
export var h3 = "TwoBoxes-module--h3--38fbf";
export var label = "TwoBoxes-module--label--24d62";
export var pattern = "TwoBoxes-module--pattern--8aa70";
export var row = "TwoBoxes-module--row--2b19d";
export var section = "TwoBoxes-module--section--f9b9c";
export var title = "TwoBoxes-module--title--0970a";