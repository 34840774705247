import React from "react";
import ReactHtmlParser from "html-react-parser";

import { cn } from "../../utils";
import * as styles from "./TwoBoxes.module.scss";
import ITwoBoxes from "./TwoBoxes.type";
import Heading from "../../components/Typography/Heading/Heading";
import Pattern from "../../components/Pattern/Pattern";

const TwoBoxes = (props: ITwoBoxes) => {
    return (
        <section className={styles.section}>
            <div className={cn("container", styles.container)}>
                <Heading level="h2" className={styles.title}>
                    {props.title}
                </Heading>
                <div className={styles.row}>
                    {props.columns.map((item) => (
                        <div key={item.title} className={styles.box}>
                            <Heading level="h4" className={styles.label}>
                                {item.label}
                            </Heading>
                            <Heading level="h3" className={styles.h3}>
                                {item.title}
                            </Heading>
                            <div className={styles.content}>
                                {ReactHtmlParser(item.content)}
                            </div>
                        </div>
                    ))}
                    <Pattern className={styles.pattern} />
                </div>
                <p className={styles.description}>{props.description}</p>
            </div>
        </section>
    );
};

export default TwoBoxes;
